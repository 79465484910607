import React from "react";
import NavBar from "../../components/molecules/NavBar";

function ForbiddenPage() {
  return (
    <div className="flex flex-col h-screen bg-slate-100">
      <NavBar />
      <div className="flex flex-1 justify-center items-center">
        <div className="flex gap-28 flex-col h-1/2 w-11/12 md:1/2">
          <h1 className="text-3xl self-center font-robotocond font-bold">
            Error 403 - Forbidden
          </h1>
          <p className="self-center font-robotocond text-lg text-slate-500">
            You are not allowed to see this page
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForbiddenPage;
