// export const BASE_URL =
// "https://us-central1-drummondtechprojects.cloudfunctions.net/api2";
// export const BASE_URL = "https://us-central1-drummondtriage---homolog.cloudfunctions.net/api2";
// prod
// export const BASE_URL = "https://us-central1-drummondtriage.cloudfunctions.net/api2";
// export const BASE_URL = "http://localhost:3001";

// require('dotenv').config()
export const BASE_URL = process.env.REACT_APP_BASE_URL;



