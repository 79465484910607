import { useContext } from "react";
import { useEffect } from "react";
import TriageContext from "../../context/TriageContext";

function ServiceCheckbox({ id, service, enabled, setEnabled }) {
  const {
    servicesID,
    setServicesID,
    signState,
    setProposalServices,
    proposalInformation,
    setProposalPackages
  } = useContext(TriageContext);

  const updateProposalServices = (newArr) => {
    const proposalServices = [];
    const proposalPackages = [];
    if(proposalInformation.Proposal_Services){
    proposalInformation?.Proposal_Services.forEach((i) => {
      if (!newArr.includes(i.id)) {
        proposalServices.push(i);
      }
    });
    setProposalServices(proposalServices);
  }
    if(proposalInformation.Proposal_Packages){
    proposalInformation?.Proposal_Packages.forEach((i) => {
      if (!newArr.includes(i.id)) {
        proposalPackages.push(i);
      }
    });
    setProposalPackages(proposalPackages);
  }
  };
  
  useEffect(() => {
    if (servicesID.includes(id)) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleCheckbox = () => {
    if (!enabled) {
      const newArr = [...servicesID, id];
      setServicesID(newArr);
      updateProposalServices(newArr);
    } else {
      const newArr = servicesID.filter((i) => i !== id);
      setServicesID(newArr);
      updateProposalServices(newArr);
    }
  };
  useEffect(() => {
    toggleCheckbox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  const validateDisabled = () => {
    return signState.signedStatus;
  };

  return (
    <div className="flex self-center">
      <div className="form-control">
        <label className="label cursor-pointer">
          <input
            type="checkbox"
            className="checkbox checkbox-primary disabled:checkbox"
            disabled={validateDisabled()}
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />
        </label>
      </div>
    </div>
  );
}
export default ServiceCheckbox;
