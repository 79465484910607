/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import NavBar from "../Components/molecules/NavBar";
import ServiceCard from "../../components/molecules/ServiceCard";
import TriageContext from "../../context/TriageContext";
import TriageNavbar from "../../components/molecules/TriageNavbar";

function Diagnostics() {
  const {
    incomingInfo,
    proposalId,
    proposalGeneratedStatus,
    setProposalGeneratedStatus,
    pageLanguage,
  } = useContext(TriageContext);
  const router = useHistory();

  const servicesArrayGenerator = () => {
    const answers = incomingInfo.answers.map((element) => element.answer);
    const services = answers
      .filter((element) => element.optionServices)
      .flatMap((element) => element.optionServices);
    return services;
  };

  const generateProposalButtonSwitcher = () => {
    switch (pageLanguage) {
      case "EN":
        setProposalGeneratedStatus(`Generate Proposal`);
        break;
      case "ES":
        setProposalGeneratedStatus(`Generar Propuesta`);
        break;
      case "PT":
        setProposalGeneratedStatus(`Gerar Proposta`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    generateProposalButtonSwitcher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageLanguage]);

  const pageHeadingSwitcher = () => {
    switch (pageLanguage) {
      case "EN":
        return `Hello ${incomingInfo?.data[0].First_Name} ${incomingInfo?.data[0].Last_Name}, based on your answers, we detected that you need the following services. If you want Drummond to take care of them for you, you can click the '${proposalGeneratedStatus}' button and we will proceed to the next step.`;
      case "ES":
        return `Hola ${incomingInfo?.data[0].First_Name} ${incomingInfo?.data[0].Last_Name}, según sus respuestas, detectamos que necesita los siguientes servicios. Si desea que Drummond se encargue de ellos por usted, puede hacer clic en el botón '${proposalGeneratedStatus}' y procederemos al siguiente paso.`;
      case "PT":
        return `Olá, ${incomingInfo?.data[0].First_Name} ${incomingInfo?.data[0].Last_Name}. Baseado em suas respostas, detectamos que você precisa dos seguintes serviços. Se você quiser, a Drummond pode realizá-los para você. É só clicar no botão '${proposalGeneratedStatus}', e seguiremos ao próximo passo.`;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col h-screen items-center">
      <TriageNavbar />
      <div className="flex flex-1 flex-col items-center w-full bg-slate-100">
        <div className="w-full h-fit pt-8 flex justify-center items-center">
          <p className="font-dinbold text-center px-6 md:px-20">
            {pageHeadingSwitcher()}
          </p>
        </div>
        <div className="flex flex-col w-11/12 md:w-4/5 md:flex-row flex-wrap gap-y-12 md:gap-y-6 md:gap-x-14 flex-1 justify-center items-center p-8">
          {servicesArrayGenerator().map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </div>
        <div className="h-12"></div>
        <footer className="fixed h-12 flex justify-center items-center left-0 bottom-0 w-full bg-drummond-primary">
          <button
            onClick={() => router.push(`/proposal/${proposalId}`)}
            className="text-white h-3/4 rounded-[4px] duration-100 text-[16px] border border-white px-2 py-1 font-dinbold hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400 disabled:cursor-not-allowed disabled:bg-opacity-50 disabled:hover:bg-drummond-primary disabled:hover:bg-opacity-50"
          >
            {proposalGeneratedStatus}
          </button>
        </footer>
      </div>
    </div>
  );
}

export default Diagnostics;
