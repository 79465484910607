function InvestmentServicesHeader(props) {
  const calculateTotal = (services, packages) => {
    const servicesTotal = services ? services.reduce((acc, curr) => acc + curr.Unit_Price, 0) : 0;
  const packagesTotal = packages
    ? packages.reduce((acc, curr) => {
        const packageValue = curr.Recurrence === "Annual" ? curr.Plan_Value : curr.Plan_Monthly_Value;
        return acc + packageValue;
      }, 0)
    : 0;
    
    return servicesTotal + packagesTotal;
    
  };
  return (
    <section className="h-[75px] flex items-center justify-between w-full px-[8px] md:px-[32px] py-[22px] sticky top-0 bg-white z-10 shadow-[0px_1px_4px_rgba(0,0,0,0.16)]">
      <div className="flex items-center gap-1 md:gap-6">
        <p className="text-[20px] md:text-[24px] md:leading-[31px] font-bold font-dinbold">
           {
           props.proposalServices ? (
            `Services (${props.proposalServices?.length})`
           ) : null
           }
        </p>
        <p className="text-drummond-primary font-asap text-[12px] md:text-[14px]">
          {
          props.proposalServices ? (
          props.proposalServices?.length === 0
            ? "No services selected yet"
            : `${props.proposalServices?.length} service(s) selected`
          ) : null
            }
        </p>
        <p className="text-[20px] md:text-[24px] md:leading-[31px] font-bold font-dinbold">
             {
              props.proposalDataPackages ? (
             `Packages (${props.proposalPackages?.length})`
              ) : null
             }
          </p>
          <p className="text-drummond-primary font-asap text-[12px] md:text-[14px]">
            {
            props.proposalDataPackages ? (
            props.proposalPackages?.length === 0
              ? "No Package selected yet"
              : `${props.proposalPackages?.length} package(s) selected`
            ) : null
              }
          </p>

      </div>
      <div className="flex flex-col">
        <p className="text-primary font-dinbold text-[24px] text-right">{`Total: $${calculateTotal(props.proposalServices, props.proposalPackages)}`}</p>
        <p className="text-[12px] font-asap text-drummond-neutral-400">
          Starting on{" "}
          {Date(props.proposalDataServices ? props.proposalDataServices.createTime : props.proposalDataPackages.createTime)
            .split(" ")
            .filter((_i, index) => index <= 3 && index > 0)
            .join(" ")}
        </p>
      </div>
    </section>
  );
}

export default InvestmentServicesHeader;
