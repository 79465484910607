import {
  PaymentElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import "../../config/css/homeLoader.css";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TriageContext from "../../context/TriageContext";
import { BASE_URL } from "../../config/api/Axios";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { stripeUser, proposalInformation, paymentIntentId } = useContext(TriageContext);

  const history = useHistory();
  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required", // Stripe will handle any necessary redirects
    });
    // Check for errors and payment intent status
    if (error) {
      await axios({
        method: "put",
        baseURL: `${BASE_URL}/zoho/updateProposal`,
        data: {
          id,
          Stripe_Payment_id: paymentIntentId,
          Payment_Status: 'Failed',
        },
      });
      setMessage(error.message);
      setIsProcessing(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      await axios({
        method: "put",
        baseURL: `${BASE_URL}/zoho/updateProposal`,
        data: {
          id,
          Stripe_Payment_id: paymentIntentId,
          Payment_Status: 'Paid',
        },
      });
      setTimeout(() => {
        axios.post(
          `${BASE_URL}/stripetest/customer/set-default-method`,
          {
            customerId: stripeUser,
            crmId: proposalInformation.Contact?.id || proposalInformation.Lead?.id,
            type: proposalInformation.Contact ? 'contact' : 'lead',
            data: {
              Account_Name: proposalInformation.Proposal_Packages ? proposalInformation.Proposal_Packages[0].Account.name : proposalInformation.Proposal_Services
              [0].Account.name,
              Account_Type: "Child Account",
              New_Account: "Existing Account",
              Status: "Active",
              Parent_Account: "",
              CRM_Entity: proposalInformation.Proposal_Packages ? proposalInformation.Proposal_Packages[0].Account.id : proposalInformation.Proposal_Services
              [0].Account.id,	
              ID_Type: "",
              Client_Since: '',
              Category: "",
              Tax_ID: "",
              Entity_Type: ""},
              email:proposalInformation.Email,
              Stripe_id: ''
          }
        );
      }, 20000);
      history.push(`/proposal/${id}/next-steps`);
    }
  };

  useEffect(() => {
    if (stripe) {

      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="flex flex-col gap-6 p-8"
    >
      {message && <span className="text-red-700" id="payment-message">{message}</span>}
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400 self-center"
      >
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      
    </form>
  );
}
