import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "../../components/molecules/NavBar";
import CheckoutForm from "../../components/organisms/CheckoutForm";
import MobileFooter from "../../components/organisms/MobileFooter";
import StepsBar from "../../components/organisms/StepsBar";
import TriageContext from "../../context/TriageContext";
import { BASE_URL } from "../../config/api/Axios";
import "../../config/css/homeLoader.css";
export default function ProposalPayTest() {
  const { proposalInformation, proposalServices, stripeUser, setStripeUser,
    setPaymentIntentId } =
    useContext(TriageContext);

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  //   useEffect(() => {
  //     fetch("/config").then(async (r) => {
  //         const {publishableKey} = r.json();
  //         setStripePromise(loadStripe(publishableKey))
  //     })
  //   }, [])

  useEffect(() => {
    if (!proposalInformation.Proposal_Services && !proposalInformation.Proposal_Packages) {
      history.push(`/proposal/${id}`);
    }
  });

  useEffect(() => {
    async function getStripePublishableKey() {
      try {
        const response = await axios.get(
          `${BASE_URL}/stripetest/getstripepk`
        );
        setStripePromise(loadStripe(response.data.stripePublishableKey));
        return response.data;
      } catch (error) {
        console.error("Error fetching Stripe Publishable Key:", error);
        throw error;
      }
    }
    getStripePublishableKey();
  }, []);

  useEffect(() => {
    async function createPaymentIntent() {
      try {
        // console.log(proposalInformation);
        const response = await axios.post(
          `${BASE_URL}/stripetest/create-payment-intent`,
          {
            amount:
              proposalServices ? proposalServices.reduce((acc, curr) => acc + curr.Unit_Price, 0) *
              100 : proposalInformation.Proposal_Packages.reduce((acc, curr) => {
                const packageValue = curr.Recurrence === "Annual" ? curr.Plan_Value : curr.Plan_Monthly_Value;
                return acc + packageValue;
              }, 0) *
              100,
            currency: "usd",
            contactId: proposalInformation.Contact?.id || proposalInformation.Lead?.id,
            contactName: proposalInformation.Contact?.name || proposalInformation.Lead?.name
          }
        );
        // console.log(response.data.payment.id);

        setPaymentIntentId(response.data.payment.id)
        setClientSecret(response.data.secret);
        setStripeUser(response.data.id);
      } catch (error) {
        console.error("Error creating payment intent:", error);
        throw error;
      }
    }
    createPaymentIntent();
  }, []);

  const history = useHistory();
  const { id } = useParams();

  return (
    <main className="flex flex-col h-screen bg-white">
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 justify-start mt-10 items-center">
        <div className="flex w-full sm:w-full lg:w-[1094px] min-h-[95%] border border-white md:border-drummond-neutral-100 rounded-[5px]">
          <div className="w-1/2 p-11 h-full flex flex-col gap-6">
            <div className="">
              {/* {console.log(proposalInformation)} */}
              <p className="text-primary font-dinbold text-[24px] text-start">{`Total: $${proposalServices ? proposalServices.reduce(
                (acc, curr) => acc + curr.Unit_Price,
                0
              ) : proposalInformation.Proposal_Packages.reduce((acc, curr) => {
                const packageValue = curr.Recurrence === "Annual" ? curr.Plan_Value : curr.Plan_Monthly_Value;
                return acc + packageValue;
              },
                0
              )
            }`}</p>

            </div>
            <div className="">
              <ul>
                {proposalServices ? proposalServices.map((service, index) => {
                  return (
                    <li className="flex justify-between" key={index}>
                      <div className="flex flex-col">
                        <p className="font-dinbold text-drummond-neutral-400 text-[18px]">
                          {service.Service.name}
                        </p>
                        <p className="font-light text-drummond-neutral-300 text-[14px]">
                          {service.Payment_Terms}
                        </p>
                      </div>
                      <p className="font-dinbold text-drummond-neutral-300 text-[16px]">
                        $ {service.Unit_Price}
                      </p>
                    </li>
                  );
                }) : proposalInformation.Proposal_Packages.map((service, index) => {
                  return (
                    <li className="flex justify-between" key={index}>
                      <div className="flex flex-col">
                        <p className="font-dinbold text-drummond-neutral-400 text-[18px]">
                          {service.Packages.name}
                        </p>
                      </div>
                      <p className="font-dinbold text-drummond-neutral-300 text-[16px]">
                        $ {service.Recurrence === "Annual" ? service.Plan_Value : service.Plan_Monthly_Value}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="w-1/2 h-full">
            {(stripePromise && clientSecret) ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm />
              </Elements>
            ) : <div className="homeLoader" />}
          </div>
        </div>
      </div>
      {/* <div className="w-full border-2 border-red-500 h-16 flex items-center"></div> */}
      <MobileFooter />
    </main>
  );
}
