import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

import {
  Diagnostics,
  GenerateDiagnostic,
  Information,
  Proposal,
  BusinessFormation,
  Checkout,
  ForbiddenPage,
  Home,
  NotFound,
  ProposalInvestmentTest,
  ProposalNextSteps,
  ProposalSignTest,
  ProposalWelcomeTest,
  ValidateTriageUser,
  ProposalForm,
  ProposalPayTest,
} from "../pages";

const AllRoutes = [
  {
    key: 2,
    path: "/forbidden",
    exact: true,
    component: ForbiddenPage,
  },
  {
    key: 3,
    path: "/businessformation",
    exact: true,
    component: BusinessFormation,
  },
  {
    key: 4,
    path: "/proposal/:id",
    exact: true,
    component: Proposal,
  },
  {
    key: 5,
    exact: true,
    path: "/validateuser/:triageId",
    component: ValidateTriageUser,
  },
  {
    key: 6,
    path: "/proposal/:id/welcome",
    component: ProposalWelcomeTest,
  },
  {
    key: 7,
    path: "/checkout/:id",
    component: Checkout,
  },
  {
    key: 8,
    path: "/proposal/:id/investment",
    component: ProposalInvestmentTest,
  },
  {
    key: 9,
    path: "/proposal/:id/sign",
    component: ProposalSignTest,
  },
  {
    key: 10,
    path: "/proposal/:id/next-steps",
    component: ProposalNextSteps,
  },
  {
    key: 11,
    path: "/information",
    component: Information,
  },
  {
    key: 12,
    path: "/generatediagnostic/:data",
    component: GenerateDiagnostic,
  },
  {
    key: 13,
    path: "/diagnostics",
    component: Diagnostics,
  },
  {
    key: 14,
    path: "/proposal/:id/information",
    component: ProposalForm,
  },
  {
    key: 14,
    path: "/proposal/:id/payment",
    component: ProposalPayTest,
  },
  {
    key: 1,
    path: "/",
    component: Home,
  },
  {
    key: 15,
    path: "*",
    component: NotFound,
  },
];

function App() {
  return (
    <Switch>
      {AllRoutes.map((item) => (
        <Route component={<item.component />} {...item} />
      ))}
    </Switch>
  );
}

export default App;
