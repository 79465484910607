import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TriageContext from "../../context/TriageContext";

function MobileFooter(props) {
  const history = useHistory();
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { signState, userEmail, proposalServices, updatedInformation, proposalPackages } =
    useContext(TriageContext);

  const redirectToPage = (route) => {
    if (route === "checkout") {
      history.push(`/${route}/${id}`);
    } else {
      history.push(`/proposal/${id}/${route}`);
    }
  };

  const [validateSignatures, setValidateSignaures] = useState(
    !history.location.pathname.includes("sign") ||
      !history.location.pathname.includes("information") 
      // || 
      // !history.location.pathname.includes("payment")
  );

  const allFieldsFilled = Object.values(updatedInformation).every((section) =>
    Object.values(section).every((value) => value.trim() !== "")
  );

  const validateNextBtn = async () => {
    if (proposalServices ?  proposalServices.length < 1 : proposalPackages.length < 1) {
      setValidateSignaures(false);
    } else {
      setValidateSignaures(true);
      if (proposalServices ?  proposalServices.length < 1 : proposalPackages.length < 1) {
        setValidateSignaures(false);
      } else {
        setValidateSignaures(true);
      }
      if (
        history.location.pathname.includes("welcome") ||
        history.location.pathname.includes("next") 
        // || 
        // history.location.pathname.includes("payment")
      ) {
        setValidateSignaures(false);
      }
      if (history.location.pathname.includes("sign")) {
        // signState.forEach(i => {
        if (signState.signedStatus) {
          setValidateSignaures(true);
        } else {
          setValidateSignaures(false);
        }
        if (
          history.location.pathname.includes(
            "/proposal/" + id + "/information"
          ) &&
          !allFieldsFilled
        ) {
          setValidateSignaures(false);
        }

        // })
      }
    }
  };

  useEffect(() => {
    validateNextBtn();
  });

  const changeRoute = (type) => {
    const route = history.location.pathname;
    if (type === "next") {
      switch (route) {
        case `/proposal/${id}/welcome`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/investment`:
          redirectToPage("information");
          break;
        case `/proposal/${id}/information`:
          redirectToPage("sign");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("payment");
          break;
          case `/proposal/${id}/payment`:
            redirectToPage("next-steps");          
            break;  
        default:
          break;
      }
    }
    if (type === "back") {
      switch (route) {
        case `/proposal/${id}/investment`:
          redirectToPage("welcome");
          break;
        case `/proposal/${id}/information`:
          redirectToPage("investment");
          break;
          case `/proposal/${id}/sign`:
            redirectToPage("information");
            break;
        case `/proposal/${id}/payment`:
          redirectToPage("sign");
          break;
        case `/proposal/${id}/next-steps`:
          redirectToPage("payment");
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="w-full md:hidden h-16 flex items-center justify-between px-[32px]">
      <button
        className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400"
        onClick={() => changeRoute("back")}
      >
        Previous Step
      </button>
      {!validateSignatures ||
      history.location.pathname.includes("welcome") ||
      (history.location.pathname.includes("/proposal/" + id + "/information") &&
        !allFieldsFilled) ? (
        <div className="invisible w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400">
          Next Step
        </div>
      ) : (
        <button
          className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400"
          onClick={() => changeRoute("next")}
        >
          Next Step
        </button>
      )}
    </div>
  );
}

export default MobileFooter;
