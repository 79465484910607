import React, { useEffect, useState } from "react";
import NavBar from "../../components/molecules/NavBar";
// import Footer from "../../components/molecules/Footer";
import RejectPropModalTest from "../../components/organisms/RejectPropModalTest";
import { useContext } from "react";
import TriageContext from "../../context/TriageContext";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import StepsBar from "../../components/organisms/StepsBar";
import "../../config/css/index.css";
import InvestmentServicesHeader from "../../components/organisms/InvestmentServicesHeader";
import ServiceItemElement from "../../components/organisms/ServiceItemElement";
import MobileFooter from "../../components/organisms/MobileFooter";

export default function ProposalInvestmentTest() {
  // debugger
  const [proposalData, setProposalData] = useState({
    services: [],
    packages: [],
    ownerName: "",
    createTime: "",
    leadName: "",
  });

  const [rejectPropModalOpen, setRejectPropModalOpen] = useState(false);

  const { proposalInformation, proposalServices, proposalPackages, signState } =
    useContext(TriageContext);

  const fetchDataFromCRM = async () => {
    setProposalData({
      ownerName: "Drummond Advisors",
      createTime: proposalInformation.Created_Time,
      services: proposalInformation.Proposal_Services,
      packages:proposalInformation.Proposal_Packages,
      leadName:
        proposalInformation.Lead?.name ||
        proposalInformation.Contact?.name ||
        "",
    });
  };
  const dataObject = [];
  

  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!proposalInformation.Proposal_Services && !proposalInformation.Proposal_Packages) {
      history.push(`/proposal/${id}`);
    }
  });

  useEffect(() => {
    fetchDataFromCRM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalInformation]);

  return (
    <main className="flex flex-col h-screen bg-white">
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 justify-start mt-10 items-center">
        {/* Figma pointed to a 764px height. It doesn't work because it creates a
        scroll on the full page. */}
        <div className="w-full lg:w-[1094px] h-[95%] sm:h-[75%] lg:h-[95%] max-h-128 sm:max-h-96 lg:max-h-128 3xl:max-h-240 bg-white flex flex-col items-center rounded-[5px] border-b md:border border-drummond-neutral-100 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-lg scrollbar-h-4">
          
          <InvestmentServicesHeader
            proposalServices={proposalServices}
            proposalDataServices={proposalData.services}
            proposalPackages={proposalPackages}
            proposalDataPackages={proposalData.packages}
            />
          {/* <InvestmentPackagesHeader
            proposalPackages={proposalPackages}
            proposalDataPackages={proposalData.packages}
            /> */}

          <div className="bg-white h-full">
          <div className="flex flex-col items-center justify-between w-full bg-white min-h-full gap-[16px]">
              {
              proposalData.packages ? (
              proposalData.packages.map((service, index) => (
                <ServiceItemElement
                  service={service}
                  index={index}
                  key={index}
                />
              ))) : null
              }
              {/* {!signState.signedStatus ? (
                <div className="mb-[30.5px] w-[10] flex items-center justify-center sticky bg-white">
        
                </div>
              ) : null} */}
            
              {
              proposalData.services ? (
              proposalData.services.map((service, index) => (
                <ServiceItemElement
                  service={service}
                  index={index}
                  key={index}
                />
              ))) : null
            }
              {!signState.signedStatus ? (
                <div className="mb-[30.5px] w-[10] flex items-center justify-center sticky bg-white">
                  <button
                    className="font-dinbold text-[16px] text-drummond-primary underline"
                    onClick={() => setRejectPropModalOpen(!rejectPropModalOpen)}
                  >
                    Decline Proposal
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {rejectPropModalOpen ? (
        <RejectPropModalTest
          openState={rejectPropModalOpen}
          openCloseFunction={setRejectPropModalOpen}
        />
      ) : null}
      <MobileFooter />
    </main>
  );
}
