import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
 
const firebaseConfig = {
  apiKey: "AIzaSyD-9C2PiriahCgRtPRSeSlnMcVPnW5Hu_o",
  authDomain: "drummondtechprojects.firebaseapp.com",
  projectId: "drummondtechprojects",
  storageBucket: "drummondtechprojects.appspot.com",
  messagingSenderId: "709140088614",
  appId: "1:709140088614:web:15bfdb948883aeb81ee3e7",
  measurementId: "G-DG12EN7F7G",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
