import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom/cjs/react-router-dom.min";
import TriageContext from "../../context/TriageContext";
import drummondLogo from "../../assets/logo-drummond.jpg";
import { BASE_URL } from "../../config/api/Axios";
import "../../config/css/GenerateDiagnosticLoading.css";

export default function Proposal() {
  const { id } = useParams();
  const url = `/proposal/${id}/welcome`;
  const [redirect, setRedirect] = useState(false);

  const {
    setProposalInformation,
    setSignState,
    setUserEmail,
    setProposalServices,
    setProposalPackages,
  } = useContext(TriageContext);

  const getProposalInformation = async (id) => {
    // debugger
    try {

      const proposal = await axios({
        method: "get",
        url: `${BASE_URL}/zoho/proposal/${id}`,
      }).then((proposal) => {
        // console.log(proposal);
        return proposal;
      });

      setProposalInformation(proposal.data);
      setProposalServices(proposal.data.Proposal_Services);
      setProposalPackages(proposal.data.Proposal_Packages);
      setUserEmail(proposal.data.Email);
      setRedirect(true);

      const email = proposal.data.Email;
// console.log(proposal);
//         const result = await axios({
//           method: "get",
//           url: `${BASE_URL}/firebase/${proposal.data.Triage_ID}`,
//         });

        setSignState({
          email,
          name: proposal.data.Name,
          signedStatus: proposal.data.Stage === 'Proposal Accepted' ? true : false,
          time: proposal.data.Sign_Proposal_Date,
        });

    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
   getProposalInformation(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {redirect ? (
        <Redirect to={url} />
      ) : (
        <div className="border-4 border-red-800 h-screen flex flex-col justify-center items-center">
          <img src={drummondLogo} width="200" height="200" alt="logo" />
          <p className="flex text-xl">Searching for your proposal...</p>
          <div className="flex justify-center h-32 w-32">
            <div className="generateLoading">Loading...</div>
          </div>
        </div>
      )}
    </div>
  );
}
