/* eslint-disable no-unused-vars */
import React from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import { useContext } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import TriageContext from "../../context/TriageContext";
import NavBar from "../../components/molecules/NavBar";
import StepsBar from "../../components/organisms/StepsBar";
import MobileFooter from "../../components/organisms/MobileFooter";
import Validations from "../../utils/Validations";

export default function ProposalForm() {
  const { id } = useParams();
  const [open, setOpen] = React.useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const history = useHistory();

  const { setUpdatedInformation, updatedInformation } =
    useContext(TriageContext);
    
    const handleInputChange = (section, field, value) => {
      let isValid = true;

      if (field.includes("Email")) {
        isValid = Validations.EMAIL.isValid(value);
      }
      if (field.includes("phone") || field.includes("identification")) {
        isValid = Validations.ONLY_NUMBERS.isValid(value);
      }

      setUpdatedInformation((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [field]: value,
          // Você pode querer armazenar a validade do campo de alguma forma
          // Por exemplo: `${field}IsValid`: isValid,
        },
      }));

      // Opcional: Atualizar o estado de validade geral do formulário aqui
    };    

  return (
    <main className="flex flex-col h-screen bg-white">
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 mt-1 items-center">
        <div className="flex flex-col items-center space-y-8 w-full h-5/6 md:w-4/6 md:py-10">
          <div className="flex flex-col gap-4">
            <h1 className="self-center font-dinbold text-4xl md:text-[42px] text-drummond-neutral-400">
              Information Update
            </h1>
            <p className="text-base font-asap text-drummond-neutral-400 leading-normal p-2 md:p-0">
              To improve our service, please update your information with us.
              Your feedback is essential for a better experience. Kindly fill
              out the form below. Thank you for your continued trust. You will
              be able to continue once everything is filled.
            </p>
          </div>
          {/* {updatedInformation ? (<InfoUpdated />) : (   <div className="w-full px-2 md:p-0"> */}
          <div className="w-full px-2 md:p-2 md:flex md:gap-4 md:h-full">
            <div className="p-2 w-full">
              <Accordion open={open === 1}>
                <AccordionHeader
                  className="font-dinbold"
                  onClick={() => handleOpen(1)}
                >
                  Proposal Holder
                </AccordionHeader>
                <AccordionBody className="gap-1 md:gap-2 flex flex-col md:flex-row">
                  <input
                    className={`bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Full Name/ Legal Entity's Name"
                    name="holderName"
                    onChange={(e) => {
                      handleInputChange("holder", "name", e.target.value)
                    }
                    }
                    value={updatedInformation.holder.name}
                  ></input>
                  <input
                    className={`bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Full Address (with Zip Code)"
                    name="holderAddress"
                    onChange={(e) =>
                      handleInputChange("holder", "address", e.target.value)
                    }
                    value={updatedInformation.holder.address}
                  ></input>
                  <div className="w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.holder.identification && !Validations.ONLY_NUMBERS.isValid(updatedInformation.holder.identification) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` : `bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` }
                    type="text"
                    placeholder="EIN/CNPJ/CPF"
                    name="holderIdentification"
                    onChange={(e) =>
                      handleInputChange(
                        "holder",
                        "identification",
                        e.target.value
                        )
                      }
                      value={updatedInformation.holder.identification}
                      ></input>
                  {(!Validations.ONLY_NUMBERS.isValid(updatedInformation.holder.identification) && updatedInformation.holder.identification !== '') && <label className="text-red-500" htmlFor="signatureEmail"> Insert a valid EIN/CNPJ/CPF</label>}
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 2}>
                <AccordionHeader
                  className="font-dinbold"
                  onClick={() => handleOpen(2)}
                >
                  Signature Responsible Information
                </AccordionHeader>
                <AccordionBody className="gap-1 md:gap-2 flex flex-col md:flex-row">
                  <input
                    className={`bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Name"
                    name="signatureName"
                    onChange={(e) =>
                      handleInputChange(
                        "signatureResponsible",
                        "name",
                        e.target.value
                      )
                      
                    }
                    value={updatedInformation.signatureResponsible.name}
                  ></input>
                  <div className="flex-col w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.signatureResponsible.email && !Validations.EMAIL.isValid(updatedInformation.signatureResponsible.email) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`: `bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="email"
                    placeholder="Email"
                    name="signatureEmail"
                    onChange={(e) =>{
                      handleInputChange(
                        "signatureResponsible",
                        "email",
                        e.target.value
                        )
                        
                      }
                    }
                    value={updatedInformation.signatureResponsible.email}
                  ></input>
                  {(!Validations.EMAIL.isValid(updatedInformation.signatureResponsible.email) && updatedInformation.signatureResponsible.email !== '') && <label className="text-red-500" htmlFor="signatureEmail"> Insert a valid Email</label>}
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 3}>
                <AccordionHeader
                  className="font-dinbold"
                  onClick={() => handleOpen(3)}
                >
                  Financial Contact Information
                </AccordionHeader>
                <AccordionBody className="gap-1 md:gap-2 flex flex-col md:flex-row">
                  <input
                    className={`bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Name"
                    name="financialName"
                    onChange={(e) =>
                      handleInputChange("financial", "name", e.target.value)
                    }
                    value={updatedInformation.financial.name}
                  ></input>
                  <div className="flex-col w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.financial.email && !Validations.EMAIL.isValid(updatedInformation.financial.email) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` : `bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="email"
                    placeholder="Email"
                    name="financialEmail"
                    onChange={(e) =>
                      handleInputChange("financial", "email", e.target.value)
                    }
                    value={updatedInformation.financial.email}
                  ></input>
                  {(!Validations.EMAIL.isValid(updatedInformation.financial.email) && updatedInformation.financial.email !== '') && <label className="text-red-500" htmlFor="financialEmail"> Insert a valid Email</label>}
                   </div>
                   <div className="w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.financial.phone && !Validations.ONLY_NUMBERS.isValid(updatedInformation.financial.phone) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` : `bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Phone Number"
                    name="financialPhone"
                    onChange={(e) =>
                      handleInputChange("financial", "phone", e.target.value)
                    }
                    value={updatedInformation.financial.phone}
                  ></input>
                 {(!Validations.ONLY_NUMBERS.isValid(updatedInformation.financial.phone) && updatedInformation.financial.phone !== '') && <label className="text-red-500" htmlFor="signatureEmail"> Insert a valid Phone Number</label>}
                 </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 4}>
                <AccordionHeader
                  className="font-dinbold"
                  onClick={() => handleOpen(4)}
                >
                  Administrative Contact Information
                </AccordionHeader>
                <AccordionBody className="gap-1 md:gap-2 flex flex-col md:flex-row">
                  <input
                    className={`bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="text"
                    placeholder="Name"
                    name="administrativeName"
                    onChange={(e) =>
                      handleInputChange(
                        "administrative",
                        "name",
                        e.target.value
                      )
                    }
                    value={updatedInformation.administrative.name}
                  ></input>
                  <div className="flex-col w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.administrative.email && !Validations.EMAIL.isValid(updatedInformation.administrative.email) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` : `bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`}
                    type="email"
                    placeholder="Email"
                    name="administrativeEmail"
                    onChange={(e) =>
                      handleInputChange(
                        "administrative",
                        "email",
                        e.target.value
                      )
                    }
                    value={updatedInformation.administrative.email}
                  ></input>
                  {(!Validations.EMAIL.isValid(updatedInformation.administrative.email) && updatedInformation.administrative.email !== '') && <label className="text-red-500" htmlFor="administrativeEmail"> Insert a valid Email</label>}
                                      
                  </div>
                  <div className="w-full mb-[12px] h-[56px] rounded-[5px] px-[23px]">
                  <input
                    className={!(updatedInformation.administrative.phone && !Validations.ONLY_NUMBERS.isValid(updatedInformation.administrative.phone) ) ? `bg-white border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300`:`bg-red-50 border border-[#ECECEC] w-full mb-[12px] h-[56px] rounded-[5px] px-[23px] focus:outline-[#A21A17] font-inter text-[16px] text-drummondNeutral-300` }
                    type="text"
                    placeholder="Phone Number"
                    name="administrativePhone"
                    onChange={(e) =>
                      handleInputChange(
                        "administrative",
                        "phone",
                        e.target.value
                      )
                    }
                    value={updatedInformation.administrative.phone}
                  ></input>
                {(!Validations.ONLY_NUMBERS.isValid(updatedInformation.administrative.phone) && updatedInformation.administrative.phone !== '') && <label className="text-red-500" htmlFor="signatureEmail"> Insert a valid Phone Number</label>}
                </div>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
        <MobileFooter />
      </div>
    </main>
  );
}
