import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import NavBar from "../../components/molecules/NavBar";
import TriageContext from "../../context/TriageContext";
import { BASE_URL } from "../../config/api/Axios";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { languageSwitchObject } from "../../assets/switchObject";

function Information() {
  const [servicesIDArray, setServicesIDArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationState, setLocationState] = useState({});
  const { triagePayload, setTriagePayload, providedAnswers, pageLanguage } =
    useContext(TriageContext);
  const router = useHistory();
  const captchaRef = useRef(null);

  const CAPTCHA_SITE_KEY = "6Lc7UgAkAAAAAOcGwLH1B24YvsBz9oyU5kAReWZz";

  const handleChange = ({ target }) => {
    setTriagePayload({
      ...triagePayload,
      userInfo: { ...triagePayload.userInfo, [target.name]: target.value },
    });
  };

  const getUserLocation = async () => {
    const requestOptions = {
      method: "GET",
    };
    await fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=16394f665261410bbfcd772ba9cc93dd",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
        setLocationState({
          location: `${result.city.name} - ${result.state.name} - ${result.country.name}`,
          ip: result.ip,
          plataform: navigator.platform,
        })
      )
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getUserLocation();
    const services = providedAnswers
      .filter((element) => element.answer.optionServices)
      .flatMap((element) => element.answer.optionServices);
    const serviceIdArray = services.map((service) => service.serviceId);
    setServicesIDArray(serviceIdArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCaptchaResult = async (token) => {
    const captchaResult = await axios({
      method: "post",
      baseURL: "https://recaptchaapi-dewpbw2jyq-uc.a.run.app",
      data: {
        token,
      },
    });
    return captchaResult.data;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTriagePayload({
      ...triagePayload,
      answers: providedAnswers,
      servicesID: servicesIDArray,
    });

    const token = captchaRef.current.getValue();

    if (
      triagePayload.userInfo.firstName &&
      triagePayload.userInfo.lastName &&
      triagePayload.userInfo.email
    ) {
      setLoading(true);
      const captchaResult = await getCaptchaResult(token);

      if (captchaResult.success) {
        await axios({
          method: "post",
          baseURL: `${BASE_URL}/zoho/generatedeal`,
          data: {
            data: [
              {
                First_Name: triagePayload.userInfo.firstName,
                Last_Name: triagePayload.userInfo.lastName,
                Email: triagePayload.userInfo.email,
                Phone: triagePayload.userInfo.phone,
                Lead_Source: "User triage",
                Company: `${triagePayload.userInfo.firstName} ${triagePayload.userInfo.lastName}`,
              },
            ],
            answers: providedAnswers,
            servicesID: servicesIDArray,
            triageAccessData: locationState,
          },
        }).then((data) => {
          return data.data;
        });

        Swal.fire({
          icon: "success",
          title: "You're all set!",
          text: "Check your e-mail for the link to your diagnostic!",
          confirmButtonColor: "#991C1C",
        });

        setTimeout(() => {
          router.push("/");
          document.location.reload();
        }, 3000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Something wrong here...",
          text: "Try the captcha again.",
          confirmButtonColor: "#991C1C",
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col h-screen items-center">
      <NavBar />
      <div className="flex flex-1 flex-col items-center w-full justify-center bg-slate-100">
        <h1 className="text-center text-2xl py-2 font-robotocond">
          {languageSwitchObject.informationPageTitle[pageLanguage]}
        </h1>
        <h2 className="text-center text-md font-bold pb-2 font-robotocond">
          {languageSwitchObject.informationPageDescription[pageLanguage]}
        </h2>
        <div className="flex flex-col justify-center h-5/6 rounded-lg bg-white w-11/12 md:w-1/2 shadow-sm">
          {loading ? (
            <div className="generateLoading">Loading...</div>
          ) : (
            <form className="flex flex-col mt-2 justify-around items-center h-5/6 ">
              <div className="col-span-6 sm:col-span-3 w-full flex flex-col">
                <label
                  htmlFor="email-address"
                  className="font-robotocond block text-md font-medium w-5/6 self-center text-gray-700"
                >
                  {languageSwitchObject.emailAddress[pageLanguage]}
                </label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="email"
                  id="email-address"
                  required
                  className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-3 w-full flex flex-col">
                <label
                  htmlFor="name"
                  className="font-robotocond block text-md font-medium w-5/6 self-center text-gray-700"
                >
                  {languageSwitchObject.firstName[pageLanguage]}
                </label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="firstName"
                  id="name"
                  required
                  className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="col-span-6 sm:col-span-3 w-full flex flex-col">
                <label
                  htmlFor="name"
                  className="font-robotocond block text-md font-medium w-5/6 self-center text-gray-700"
                >
                  {languageSwitchObject.lastName[pageLanguage]}
                </label>
                <input
                  onChange={handleChange}
                  type="text"
                  name="lastName"
                  id="name"
                  required
                  className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="pb-3 col-span-6 sm:col-span-3 w-full flex flex-col">
                <label
                  htmlFor="name"
                  className="font-robotocond block text-md font-medium w-5/6 self-center text-gray-700"
                >
                  {languageSwitchObject.phone[pageLanguage]}
                </label>
                <input
                  onChange={handleChange}
                  type="tel"
                  name="phone"
                  id="Phone"
                  className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ReCAPTCHA
                size={"normal"}
                sitekey={CAPTCHA_SITE_KEY}
                ref={captchaRef}
              />
              <button
                type="submit"
                className="px-2 py-1 self-center mt-3 mb-2 bg-red-800 rounded-md text-white duration-100 hover:bg-red-600"
                onClick={handleSubmit}
              >
                {languageSwitchObject.submitButton[pageLanguage]}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Information;
