import React from "react";

export default function CheckoutCard({ service }) {
  return (
    <div className="overflow-hidden w-full rounded-md bg-white shadow-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {service.Service.name}
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Service Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {service.Service.name}
            </dd>
            <dt className="text-sm font-medium text-gray-500">Recurrence</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {service.Recurrence}
            </dd>
            <dt className="text-sm font-medium text-gray-500">Price</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {`$${service.Unit_Price}`}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
