import { useEffect, useState } from "react";

function SelectComponent(props) {
  const { information } = props;
  const [selectValue, setSelectValue] = useState({});

  useEffect(() => {
    setSelectValue(information[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOnChange = ({ target }) => {
    const object = information.find((info) => info.name === target.value);
    setSelectValue(object);
  };

  const selectConditional = () => {
    if (selectValue.subOptions) {
      return (
        <select>
          {selectValue.subOptions &&
            selectValue.subOptions.map((sub, index) => (
              <option key={index}>{sub.name}</option>
            ))}
        </select>
      );
    }
    return null;
  };

  return (
    <>
      <select onChange={selectOnChange}>
        {information.map((optional, index) => (
          <option value={optional.name} key={index}>
            {optional.name}
          </option>
        ))}
      </select>
      {selectValue.subOptions && selectConditional()}
    </>
  );
}

export default SelectComponent;
