// import React from "react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "../../components/molecules/NavBar";
import DynamicForm from "../../components/organisms/DynamicForm";
import TriageContext from "../../context/TriageContext";
import businessMan from "../../assets/business_man2.png";
import { Tab } from "@headlessui/react";
import { languageSwitchObject } from "../../assets/switchObject";

function BusinessFormationHome() {
  const router = useHistory();
  const [currQuestion, setCurrQuestion] = useState();
  const [loadingState, setLoadingState] = useState(true);
  const [optionDescriptions, setOptionDescriptions] = useState([]);
  const [currentDescription, setCurrentDescription] = useState({});

  const {
    setCurrAnswer,
    nextQuestionContext,
    answeredQuestionsId,
    setAnsweredQuestionsId,
    providedAnswers,
    currAnswer,
    setProvidedAnswers,
    pageLanguage,
  } = useContext(TriageContext);

  console.log(currQuestion);

  const questionTitleSwitcher = () => {
    switch (pageLanguage) {
      case "EN":
        return currQuestion && currQuestion.questionTitle.english;
      case "ES":
        return currQuestion && currQuestion.questionTitle.spanish;
      case "PT":
        return currQuestion && currQuestion.questionTitle.portuguese;
      default:
        break;
    }
  };

  const questionDescriptionSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return currQuestion && element.questionDescription.english;
      case "ES":
        return currQuestion && element.questionDescription.spanish;
      case "PT":
        return currQuestion && element.questionDescription.portuguese;
      default:
        break;
    }
  };

  const translatedQDescription = questionDescriptionSwitcher(currQuestion);

  const buttonTextSwitcher = (string) => {
    switch (pageLanguage) {
      case "EN":
        if (string === "Next") {
          return "Next";
        } else {
          return "Back";
        }
      case "ES":
        if (string === "Next") {
          return "Próximo";
        } else {
          return "Volver";
        }
      case "PT":
        if (string === "Next") {
          return "Próximo";
        } else {
          return "Voltar";
        }
      default:
        break;
    }
  };

  const optionDescriptionSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return element.optionDescription.english;
      case "ES":
        return element.optionDescription.spanish;
      case "PT":
        return element.optionDescription.portuguese;
      default:
        break;
    }
  };

  const optionTitleSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return element.optionTitle.english;
      case "ES":
        return element.optionTitle.spanish;
      case "PT":
        return element.optionTitle.portuguese;
      default:
        break;
    }
  };

  useEffect(() => {
    if (currQuestion?.type !== "text") {
      setOptionDescriptions(
        currQuestion?.questionOptions
          // eslint-disable-next-line array-callback-return
          .map((option) => {
            if (option.optionDescription) {
              return {
                optionTitle: optionTitleSwitcher(option).replace(
                  new RegExp('"', "g"),
                  ""
                ),
                description: optionDescriptionSwitcher(option),
              };
            }
          })
          .filter((item) => item)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currQuestion, pageLanguage]);

  useEffect(() => {
    setCurrentDescription({
      optionTitle:
        currAnswer &&
        optionTitleSwitcher(currAnswer).replace(new RegExp('"', "g"), ""),
      description: currAnswer && optionDescriptionSwitcher(currAnswer),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currAnswer, pageLanguage]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getHomeQuestion = () => {
    axios({
      method: "get",
      url: "https://questionsapi-dewpbw2jyq-uc.a.run.app/questions/4rjAYgtAdVjQNHisCCs4",
    }).then((questionData) => {
      setCurrQuestion(questionData.data);
      setLoadingState(false);
    });
  };

  const getNextQuestion = (questionIdentifier) => {
    setLoadingState(true);
    if (currAnswer.triageEnd) {
      setAnsweredQuestionsId((prevState) => [...prevState, questionIdentifier]);
      setProvidedAnswers((prevState) => [
        ...prevState,
        {
          question: currQuestion.questionTitle.english,
          answer: currAnswer,
        },
      ]);
      setLoadingState(true);
      setCurrAnswer();
      router.push("/information");
    } else {
      axios({
        method: "get",
        url: `https://us-central1-drummondtechprojects.cloudfunctions.net/api/triage/next-question?nextQuestion=${questionIdentifier}&language=en`,
      }).then((questionData) => {
        setAnsweredQuestionsId((prevState) => [
          ...prevState,
          questionIdentifier,
        ]);
        setProvidedAnswers((prevState) => [
          ...prevState,
          {
            question: currQuestion.questionTitle.english,
            answer: currAnswer,
          },
        ]);
        setCurrQuestion(questionData.data.en);
        setLoadingState(false);
        setCurrAnswer();
      });
    }
  };

  const getLastQuestion = (questionIdentifier) => {
    setLoadingState(true);
    axios({
      method: "get",
      url: `https://us-central1-drummondtechprojects.cloudfunctions.net/api/triage/next-question?nextQuestion=${questionIdentifier}&language=en`,
    }).then((questionData) => {
      setCurrQuestion(questionData.data.en);
      setLoadingState(false);
      setCurrAnswer();
    });
  };

  const handleBackButton = async () => {
    const newArray = answeredQuestionsId;
    const newProvidedAnswers = providedAnswers;
    newProvidedAnswers.pop();
    newArray.pop();
    setAnsweredQuestionsId(newArray);
    setCurrAnswer();
    // setTriagePayload({
    //   answeredQuestions: newArray,
    //   answers: newProvidedAnswers,
    // });
    if (newArray.length >= 1) {
      getLastQuestion(answeredQuestionsId[newArray.length - 1]);
    } else {
      setCurrAnswer();
      getHomeQuestion();
    }
  };

  useEffect(() => {
    getHomeQuestion();
  }, []);

  return (
    <div className="drawer">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col h-screen bg-slate-100">
        {/* <!-- Page content here --> */}
        <NavBar />
        <div className="flex flex-1 flex-col md:flex-row-reverse bg-slate-100 md:gap-16 justify-center items-center">
          <div className="h-5/6 hidden w-1/3 md:flex justify-center items-center">
            {currAnswer && currAnswer.optionDescription ? (
              <div className="h-3/5 flex flex-col w-full bg-white shadow-md rounded-lg">
                <div className="h-1/5 bg-red-900 rounded-md flex justify-center items-center">
                  <h2 className="text-white text-md font-robotocond">
                    Option: {currentDescription.optionTitle}
                  </h2>
                </div>
                <div
                  className="flex flex-col flex-1 items-center justify-center text-sm p-4"
                  dangerouslySetInnerHTML={{
                    __html: currentDescription.description,
                  }}
                >
                  {/* {currentDescription.description} */}
                </div>
              </div>
            ) : (
              <img
                src={businessMan}
                alt="business man"
                className="h-3/4 w-full"
              />
            )}
          </div>
          <div className="bg-white overflow-y-scroll md:p-4 flex flex-col h-5/6 p-1 rounded-lg w-full md:w-1/2 shadow-md">
            {loadingState ? (
              <div className="flex h-full w-full items-center justify-center">
                <div className="homeLoader">Loading...</div>
              </div>
            ) : (
              <>
                <div className="py-2 flex items-center justify-center flex-col h-fit">
                  <p className="text-2xl font-robotocond text-center pt-1">
                    {questionTitleSwitcher()}
                  </p>
                  {currQuestion?.questionDescription ? (
                    <p
                      className="text-center font-sans text-slate-600 py-1"
                      dangerouslySetInnerHTML={{
                        __html: translatedQDescription,
                      }}
                    ></p>
                  ) : null}
                </div>
                <div className="flex flex-1 justify-center items-center">
                  {currQuestion && <DynamicForm element={currQuestion} />}
                </div>
                {loadingState ? (
                  <div className="h-14"></div>
                ) : (
                  <div className="flex items-center h-20 justify-evenly md:p-4 md:justify-between w-full md:w-full">
                    {currQuestion?.questionId !== "4rjAYgtAdVjQNHisCCs4" ? (
                      <button
                        className="py-1 px-5 border duration-100 border-white text-white rounded-md bg-red-900 hover:bg-red-600"
                        onClick={handleBackButton}
                      >
                        {buttonTextSwitcher("Back")}
                      </button>
                    ) : (
                      <div className="invisible text-white py-1 px-5">Back</div>
                    )}
                    {currAnswer?.optionDescription ? (
                      <label
                        htmlFor="my-drawer"
                        className="drawer-button bg-red-200 duration-150 hover:text-white hover:bg-red-800  text-xs rounded-md px-0.5 py-1 flex justify-center items-center cursor-pointer md:hidden"
                      >
                        {languageSwitchObject.descriptionButton[pageLanguage]}
                      </label>
                    ) : (
                      <div className="h-10 w-10"></div>
                    )}
                    <button
                      disabled={currAnswer ? false : true}
                      className="py-1 px-5 border duration-100 border-white text-white rounded-md bg-red-900 hover:bg-red-600 disabled:opacity-40 disabled:hover:bg-red-900"
                      onClick={() => {
                        getNextQuestion(nextQuestionContext.nextQuestion);
                      }}
                    >
                      {buttonTextSwitcher("Next")}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer" className="drawer-overlay"></label>
        <ul className="menu flex only:flex-col w-80 md:w-1/3 bg-base-100 text-base-content">
          {/* <!-- Sidebar content here --> */}
          {/* <div className="border-2 w-full h-16 bg-red-800 flex justify-center items-center">
            <h2 className="text-white font-robotocond">
              Selected option: {currentDescription?.option}
            </h2>
          </div> */}
          <div
            className="flex flex-col p-4 flex-1 justify-center items-center"
            dangerouslySetInnerHTML={{ __html: currentDescription.description }}
          >
            {/* <p className="text-center">{currentDescription?.description}</p> */}
            {/* <ul className="list-disc">
              {currentDescription.description &&
                currentDescription?.description
                  .split(";")
                  .map((item, index) => <li key={index}>- {item}</li>)}
            </ul> */}
          </div>
          {/* <div className="w-full h-12 flex justify-center items-center gap-20"> */}
          <div className="w-full max-w-md px-2 py-16 sm:px-0">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-red-400/20 p-1">
                {optionDescriptions?.map((option, index) => {
                  return (
                    <Tab
                      key={index}
                      onClick={() => setCurrentDescription(option)}
                      className={({ selected }) =>
                        classNames(
                          "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-white",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-red-400 focus:outline-none focus:ring-2",
                          option.optionTitle === currentDescription.optionTitle
                            ? "bg-red-800 shadow"
                            : "text-white hover:bg-white/[0.12] hover:text-white"
                        )
                      }
                    >
                      {option.optionTitle}
                      {/* Victor Matheus Lindos amigos */}
                    </Tab>
                  );
                })}
              </Tab.List>
            </Tab.Group>
          </div>

          {/* </div> */}
          <div className="w-full h-12 flex flex-col">
            <div className="bg-white self-center h-12 w-1/6">
              <label
                htmlFor="my-drawer"
                className="drawer-button text-red-800 h-full w-full flex justify-center items-center text-3xl"
              >
                X
              </label>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default BusinessFormationHome;
