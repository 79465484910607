/* eslint-disable no-debugger */
import moment from 'moment';

const REGEX_PATTERN = {
  CPF: /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}|\d{3}\.?\d{3}\.?\d{3}-?\d{2})$/,
  EMAIL:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  PASSWORD: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
  ITIN: /^(?:\d{3}\d{2}\d{4}|\d{2}\d{7})$/,
  EIN: /^\d{2}-?\d{7}$/,
  SSN: /^(?:\d{3}\d{2}\d{4}|\d{2}\d{7})$/,
  SSNTIN: /^(?:\d{3}\d{2}\d{4}|\d{2}\d{7})$/,
  NO_NUMBERS: /^[^\d]+$/,
};

const CPF = {
  verifierDigit(digits) {
    const numbers = digits.split('').map((number) => parseInt(number, 10));
    const modulus = numbers.length + 1;
    const multiplied = numbers.map(
      (number, index) => number * (modulus - index),
    );
    const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;
    return mod < 2 ? 0 : 11 - mod;
  },
  strip(number, strict = false) {
    const regex = strict ? /[.-]/g : /[^\d]/g;
    return (number.toString() || '')?.replace(regex, '');
  },
  isValid(number, strict = false) {
    const pattern = REGEX_PATTERN.CPF;
    const BLACKLIST = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
      '12345678909',
    ];

    if (!pattern.test(number)) {
      return false;
    }

    const stripped = CPF.strip(number, strict);

    if (!stripped) {
      return false;
    }

    if (stripped.length !== 11) {
      return false;
    }

    if (BLACKLIST.includes(stripped)) {
      return false;
    }

    let numbers = stripped.substr(0, 9);
    numbers += CPF.verifierDigit(numbers);
    numbers += CPF.verifierDigit(numbers);
    return numbers.substr(-2) === stripped.substr(-2);
  },
  insertMask(cpf) {
    cpf = cpf.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  },
};

const EMAIL = {
  isValid(str) {
    const pattern = REGEX_PATTERN.EMAIL;

    return pattern.test(str);
  },
};

const PHONE = {
  insertMask(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
    v = v.replace(/(\d)(\d{4})$/, '$1-$2');
    return v;
  },
};
const PHONES = {
  isValid(numero){
  

  // Removendo caracteres não-numéricos para simplificar a verificação
  const numeroLimpo = numero.replace(/[^0-9]/g, '');

  // Expressão regular para telefone dos EUA
  const regexEUA = /^[0-9]{10}$/;
  
  // Expressão regular para telefone do Brasil (fixo e móvel)
  const regexBrasil = /^(?:[1-9]{2})9?[0-9]{8}$/;

  // Verifica se é um número dos EUA ou Brasil baseado no comprimento e, possivelmente, no prefixo
  if (numeroLimpo.length === 10) {
      // Assume EUA se tiver exatamente 10 dígitos
      return regexEUA.test(numeroLimpo);
  } else if (numeroLimpo.length === 10 || numeroLimpo.length === 11) {
      // Assume Brasil se tiver 10 ou 11 dígitos (fixo ou móvel com o 9 na frente)
      return regexBrasil.test(numeroLimpo);
  } else {
      // Não corresponde a nenhum dos formatos esperados
      return false;
  }
}
}

const PASSWORD = {
  getPasswordStrength(password) {
    const lowerCaseRegex = '(?=.*[a-z])';
    const upperCaseRegex = '(?=.*[A-Z])';
    const symbolsRegex = '(?=.*[!@#$%^&*])';
    const numericRegex = '(?=.*[0-9])';

    let strength = {
      id: 0,
      value: undefined,
      length: undefined,
      contains: [],
    };

    let passwordContains = [];

    if (new RegExp(`^${lowerCaseRegex}`).test(password)) {
      passwordContains = [
        ...passwordContains,
        {
          message: 'lowercase',
        },
      ];
    }

    if (new RegExp(`^${upperCaseRegex}`).test(password)) {
      passwordContains = [
        ...passwordContains,
        {
          message: 'uppercase',
        },
      ];
    }

    if (new RegExp(`^${symbolsRegex}`).test(password)) {
      passwordContains = [
        ...passwordContains,
        {
          message: 'symbol',
        },
      ];
    }

    if (new RegExp(`^${numericRegex}`).test(password)) {
      passwordContains = [
        ...passwordContains,
        {
          message: 'number',
        },
      ];
    }

    const strongRegex = new RegExp(
      `^${lowerCaseRegex}${upperCaseRegex}${numericRegex}${symbolsRegex}(?=.{8,})`,
    );
    const mediumRegex = new RegExp(
      `^${lowerCaseRegex}${upperCaseRegex}${numericRegex}(?=.{6,})`,
    );

    if (strongRegex.test(password)) {
      strength = {
        id: 2,
        value: 'Strong',
      };
    } else if (mediumRegex.test(password)) {
      strength = {
        id: 1,
        value: 'Medium',
      };
    } else {
      strength = {
        id: 0,
        value: 'Weak',
      };
    }

    strength.length = password.length;
    strength.contains = passwordContains;
    return strength;
  },
  isValid(str) {
    const pattern = REGEX_PATTERN.PASSWORD;

    if (pattern.test(str)) return true;

    return false;
  },
};

const MONEY = {
  insertMask(v, locale = 'pt-BR', currency = 'BRL') {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });

    return formatter.format(v);
  },
};

const ITIN = {
  isValid(str) {
    const pattern = REGEX_PATTERN.ITIN;

    return pattern.test(str);
  },
};

const SSN = {
  isValid(str) {
    const pattern = REGEX_PATTERN.SSN;

    return pattern.test(str);
  },
};

const SSNTIN = {
  isValid(str) {
    const pattern = REGEX_PATTERN.SSNTIN;

    return pattern.test(str);
  },
};

const DATE = {
  isValid(str) {
    const format = 'MM/DD/YYYY';
    const today = moment();
    const birthDate = moment(str, format, true);

    // Check if the input is a valid date and the user is between 0 and 120 years old

    // TODO => CHANGE VALIDATION TO HANDLE FUTURE DATES
    return (
      birthDate.isValid() &&
      today.diff(birthDate, 'years') >= -100 &&
      today.diff(birthDate, 'years') <= 100
    );
  },

  format(str, format = 'MM/DD/YYYY') {
    return moment(str).format(format);
  },

  getGMT() {
    return -(new Date().getTimezoneOffset() / 60);
  },

  getUtcNow() {
    return moment().utc();
  },

  subtractDate(startDate, endDate, format = 'MM/DD/YYYY') {
    const newDate = moment(startDate).diff(endDate);

    return moment(newDate).format(format);
  },
};

const DATE_OF_BIRTH = {
  isValid(dateString) {
    const currentDate = moment();
    const birthDate = moment(dateString, 'MM/DD/YYYY', true);

    if (!birthDate.isValid()) {
      return false; // Invalid date format
    }

    const isPastDate = birthDate.isBefore(currentDate, 'day');
    const isOver120YearsAgo = currentDate.diff(birthDate, 'years') > 120;

    return isPastDate && !isOver120YearsAgo;
  },

  format(str, format = 'MM/DD/YYYY') {
    return moment(str).format(format);
  },

  getGMT() {
    return -(new Date().getTimezoneOffset() / 60);
  },

  getUtcNow() {
    return moment().utc();
  },

  subtractDate(startDate, endDate, format = 'MM/DD/YYYY') {
    const newDate = moment(startDate).diff(endDate);

    return moment(newDate).format(format);
  },
};

const EXPIRY_DATE = {
  isValid(dateString) {
    const currentDate = moment();
    const expiryDate = moment(dateString, 'MM/DD/YYYY', true);

    if (!expiryDate.isValid()) {
      return false; // Invalid date format
    }

    const yearsUntilExpiry = expiryDate.diff(currentDate, 'years', true); // Use true to get a floating-point number

    return yearsUntilExpiry >= 0 && yearsUntilExpiry <= 100;
  },

  format(str, format = 'MM/DD/YYYY') {
    return moment(str).format(format);
  },

  getGMT() {
    return -(new Date().getTimezoneOffset() / 60);
  },

  getUtcNow() {
    return moment().utc();
  },

  subtractDate(startDate, endDate, format = 'MM/DD/YYYY') {
    const newDate = moment(startDate).diff(endDate);

    return moment(newDate).format(format);
  },
};

const NO_NUMBERS = {
  isValid(str) {
    return REGEX_PATTERN.NO_NUMBERS.test(str);
  },
};

const ONLY_NUMBERS = {
  isValid(str) {
    const pattern = /^[0-9]+$/;
    return pattern.test(str);
  },
};

const ONLY_LETTERS = {
  isValid(str) {
    const pattern = /^[a-zA-Z]+$/;
    return pattern.test(str);
  },
};

const Validations = {
  CPF,
  EMAIL,
  PASSWORD,
  PHONE,
  MONEY,
  ITIN,
  SSN,
  SSNTIN,
  DATE_OF_BIRTH,
  EXPIRY_DATE,
  DATE,
  NO_NUMBERS,
  ONLY_NUMBERS,
  ONLY_LETTERS,
  PHONES
};

export default Validations;