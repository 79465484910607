import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TriageContext from "../../context/TriageContext";
import Validations from "../../utils/Validations";

export default function StepsBar(props) {
  const STEP = "step step-neutral cursor-pointer";
  const STEP_PRIMARY = "step step-primary cursor-pointer";
  const history = useHistory();
  const {
    signState,
    userEmail,
    proposalServices,
    updatedInformation,
    // proposalInformation,
  } = useContext(TriageContext);
  const { id } = useParams();

  // console.log({
  //   proposalInformation,
  //   signState,
  // });
  const [footerState, setFooterState] = useState({
    welcome: STEP_PRIMARY,
    summary: STEP,
    investment: STEP,
    information: STEP,
    payment: STEP,
    sign: STEP,
    nextSteps: STEP,
  });

  const [validateSignatures, setValidateSignaures] = useState(
    !history.location.pathname.includes("sign") ||
      !history.location.pathname.includes("information")
  );

  const allFieldsFilled = Object.values(updatedInformation).every((section) =>
    Object.entries(section).every(([key, value]) => {
      // Se o campo for um email, verifica se está preenchido e é válido
      if (key.includes("email")) {
        return value.trim() !== "" && Validations.EMAIL.isValid(value);
      }
      // Para outros campos, apenas verifica se estão preenchidos
      return value.trim() !== "";
    })
  );

  useEffect(() => {
    setSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const validateInputs = () => {
  //   const { financial, signatureResponsible, administrative } = updatedInformation;

  //   if (financial && signatureResponsible && administrative &&
  //       financial.email && signatureResponsible.email && administrative.email) {
  //     const emails = [
  //       Validations.EMAIL.isValid(financial.email),
  //       Validations.EMAIL.isValid(signatureResponsible.email),
  //       Validations.EMAIL.isValid(administrative.email)
  //     ];
  //     console.log(emails.every((a) => a === true));
  //     console.log(emails);
  //     // return !emails.every((a) => a === true);
  //     return true
  //   } else {
  //     return false;
  //   }
  // }

  useEffect(() => {
    validateNextBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalServices]);

  // useEffect(() => {
  //   console.log(proposalServices);
  // }, [proposalServices]);

  useEffect(() => {
    validateNextBtn();
  }, [updatedInformation]);

  const setSteps = () => {
    const validate = history.location.pathname || "";
    switch (validate) {
      case `/proposal/${id}/investment`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP,
          payment: STEP,
          sign: STEP,
          nextSteps: STEP,
        });
        break;
      case `/proposal/${id}/payment`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          payment: STEP_PRIMARY,
          nextSteps: STEP,
        });
        break;
      case "/proposal/sign":
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          payment: STEP,
          nextSteps: STEP,
        });
        break;
      case `/proposal/${id}/information`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          payment: STEP,
          sign: STEP,
          nextSteps: STEP,
        });
        break;
      case "/proposal/next-steps":
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          payment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP_PRIMARY,
        });
        break;
      case `/proposal/${id}/sign`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          payment: STEP,
          nextSteps: STEP,
        });
        break;
      case `/proposal/${id}/next-steps`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          information: STEP_PRIMARY,
          payment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP_PRIMARY,
        });
        break;
      default:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP,
          information: STEP,
          payment: STEP,
          sign: STEP,
          nextSteps: STEP,
        });
    }
  };

  const redirectToPage = (route) => {
    if (route === "checkout") {
      history.push(`/${route}/${id}`);
    } else {
      history.push(`/proposal/${id}/${route}`);
    }
  };

  const changeRoute = (type) => {
    const route = history.location.pathname;
    if (type === "next") {
      switch (route) {
        case `/proposal/${id}/welcome`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/investment`:
          if (signState.signedStatus) {
            redirectToPage("next-steps");
          } else redirectToPage("information");
          break;
        case `/proposal/${id}/information`:
          redirectToPage("sign");
          break;
        case `/proposal/${id}/payment`:
          redirectToPage("next-steps");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("payment");
          break;
        default:
          break;
      }
    }
    if (type === "back") {
      switch (route) {
        case `/proposal/${id}/investment`:
          redirectToPage("welcome");
          break;
        case `/proposal/${id}/information`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/payment`:
          if (signState.signedStatus) {
            redirectToPage("investment");
          } else redirectToPage("sign");

          break;
        case `/proposal/${id}/sign`:
          redirectToPage("information");
          break;
        case `/proposal/${id}/next-steps`:
          if (signState.signedStatus) {
          redirectToPage("investment");
          } else redirectToPage("sign");
          break;
        default:
          break;
      }
    }
  };

  const validateNextBtn = async () => {
    if (proposalServices?.length < 1) {
      setValidateSignaures(false);
    } else {
      setValidateSignaures(true);
      if (proposalServices?.length < 1) {
        setValidateSignaures(false);
      } else {
        setValidateSignaures(true);
      }
      if (
        history.location.pathname.includes("welcome") ||
        history.location.pathname.includes("next")
      ) {
        setValidateSignaures(false);
      }
      if (history.location.pathname.includes("sign")) {
        // signState.forEach(i => {
        if (signState.signedStatus) {
          setValidateSignaures(true);
        } else {
          setValidateSignaures(false);
        }
        if (
          history.location.pathname.includes(
            "/proposal/" + id + "/information"
          ) &&
          !allFieldsFilled
        ) {
          setValidateSignaures(false);
        }

        // })
      }
    }
  };

  const signNextStep = () => {
    if (history.location.pathname.includes("sign")) {
      if (signState.email === userEmail && signState.signedStatus) {
        redirectToPage("next-steps");
      }
    }
  };

  return (
    <div className="pt-2 hidden md:flex w-full">
      <div className="flex justify-center w-full items-baseline">
        {history.location.pathname.includes("welcome") ? (
          <div className="invisible w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400">
            Previous Step
          </div>
        ) : (
          <button
            className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-drummond-secondary-300 hover:text-white active:text-white active:bg-drummond-secondary-400"
            onClick={() => changeRoute("back")}
          >
            Previous Step
          </button>
        )}
        <ul className="hidden py-1 sm:grid w-7/12 lg:w-9/12 text-2xl font-dinlight steps steps-horizontal">
          <li
            className={`${footerState.welcome} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="welcome"
            // onClick={() => redirectToPage("welcome")}
          >
            Welcome
          </li>
          <li
            className={`${footerState.investment} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="investment"
            // onClick={() => redirectToPage("investment")}
          >
            Investment
          </li>
          <li
            className={`${footerState.information} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="information"
            // onClick={() => redirectToPage("information")}
          >
            Information
          </li>
          <li
            className={`${footerState.sign} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="sign"
            // onClick={() => redirectToPage("sign")}
          >
            Sign
          </li>
          <li
            className={`${footerState.payment} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="payment"
            // onClick={() => redirectToPage("payment")}
          >
            Payment
          </li>

          <li
            className={`${footerState.nextSteps} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="nextSteps"
            // onClick={() => signNextStep()}
          >
            Next Steps
          </li>
        </ul>
        {!validateSignatures ||
        history.location.pathname.includes("welcome") ||
        history.location.pathname.includes("payment") ||
        (history.location.pathname.includes(
          "/proposal/" + id + "/information"
        ) &&
          !allFieldsFilled) ? (
          <div
            className={`invisible w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400`}
          >
            Next Step
          </div>
        ) : (
          <button
            className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400"
            onClick={() => changeRoute("next")}
          >
            Next Step
          </button>
        )}
      </div>
    </div>
  );
}
