import { Routes } from "./routes";

import "./config/css/App.css";
import "./config/css/QuestionLoading.css";
import "./config/css/homeLoader.css";
import "./config/css/paymentButtonLoader.css";

function App() {
  return <Routes />;
}

export default App;
