/* eslint-disable no-unused-vars */
import { CalendarDaysIcon, CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import NavBar from "../../components/molecules/NavBar";
import ToSBoard from "../../components/molecules/ToSBoard";
import MobileFooter from "../../components/organisms/MobileFooter";
import SignatureComp from "../../components/organisms/SignatureComp";
import StepsBar from "../../components/organisms/StepsBar";
import TriageContext from "../../context/TriageContext";

export default function ProposalSignTest() {
  const [proposalData, setProposalData] = useState({
    ownerName: "",
    createTime: "",
    leadName: "",
  });

  const [tosCheck, setTosCheck] = useState(false);

  const {
    signState,
    proposalInformation,
    userAuthenticationStatus,
    setUserAuthenticationStatus,
    proposalServices,
    proposalPackages
  } = useContext(TriageContext);

  const verifyToSDisabled = () => {
    if (userAuthenticationStatus || (!proposalServices && !proposalInformation.Proposal_Packages)) {
      return true;
    }
    if (signState.signedStatus) {
      return true;
    }
  };

  const history = useHistory();
  const { id } = useParams();

  const fetchDataFromCRM = async () => {
    setProposalData({
      ownerName: "Drummond Advisors",
      createTime: proposalInformation?.Created_Time,
      leadName:
        proposalInformation.Lead?.name ||
        proposalInformation.Contact?.name ||
        "",
    });
  };

  useEffect(() => {
    // console.log(proposalInformation.Email);
    if (!proposalInformation.Proposal_Services && !proposalInformation.Proposal_Packages) {
      history.push(`/proposal/${id}`);
    }
  });

  useEffect(() => {
    fetchDataFromCRM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signState.signedStatus) {
      setTosCheck(true);
    }
    if (userAuthenticationStatus) {
      setTosCheck(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signState]);

  return (
    <main className="flex flex-col h-screen bg-white">
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 justify-start mt-10 items-center">
        <div className="flex flex-col w-full sm:w-full lg:w-[1094px] min-h-[95%] border border-white md:border-drummond-neutral-100 rounded-[5px]">
          {signState.signedStatus ? (
            <div className="w-full h-[75px] md:border-b border-white md:border-drummond-neutral-100 flex items-center justify-between px-[5px] md:px-[32px] bg-drummond-secondary-500">
              <h1 className="font-dinbold text-md md:text-[24px] flex items-center gap-1 md:gap-4">
                <CheckCircleIcon className="h-6 w-6 text-green-500" />
                You have already accepted this proposal.
              </h1>
              <label className="flex gap-1 md:p-2 items-center">
                <CalendarDaysIcon className="w-4 md:w-6 h-4 md:h-6 text-drummond-neutral-300" />
                <p className="text-drummond-neutral-400 font-asap text-xs md:text-[16px]">
                  {signState.time}
                </p>
              </label>
            </div>
          ) : (
            <div className="w-full md:h-[50px] md:border-b border-white md:border-drummond-neutral-100 flex items-center px-[20px] md:px-[32px]">
              <h1 className="font-dinbold text-[24px]">Accept Proposal</h1>
            </div>
          )}

          <div className="flex flex-col flex-1 justify-around px-[22px] md:px-[32px] md:py-[8px]">
            <div className="pt-[16px] md:pt-0 pb-[8px] md:pb-[16px]">
              <p className="font-asap text-[16px] text-drummond-neutral-400">
                Please read your terms of service as it contains important
                information.
              </p>
              <p className="font-asap text-[12px] md:text-[16px] text-drummond-neutral-400">
                If you need see the Terms and Conditions in portuguese click{" "}
                <a
                  href="https://workdrive.zohopublic.com/writer/open/gipi62a36a4a99a834684a0fe9bc0f1200185"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:underline duration-150 hover:text-drummond-secondary-50"
                >
                  here
                </a>
                .
              </p>
            </div>
            <ToSBoard />
            <div className="w-full h-12 py-8 md:py-[19px] flex items-center justify-between">
              <div className="flex self-center">
                <div className="form-control">
                  <label className="label cursor-pointer flex gap-4">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-primary disabled:checkbox"
                      checked={tosCheck}
                      onChange={() => setTosCheck(!tosCheck)}
                      disabled={verifyToSDisabled()}
                    />
                    I accept the terms of service
                  </label>
                  {(!proposalServices && !proposalPackages) && (
                    <p className="text-red-500 ml-12 text-sm font-dinbold">
                      You can't sign an empty proposal
                    </p>
                  )}
                </div>
              </div>
              <button
                disabled
                className="w-[124px] h-[33px] rounded-[4px] duration-100 font-dinbold text-[14px] text-primary border border-primary font-bold bg-white enabled:hover:bg-primary enabled:hover:text-white enabled:active:text-white enabled:active:bg-drummond-secondary-400 disabled:bg-opacity-60 disabled:text-white disabled:border-opacity-5 disabled:bg-drummond-primary disabled:cursor-not-allowed"
              >
                Download PDF
              </button>
            </div>
            {/* {!userAuthenticationStatus && !signState.signedStatus ? (
              <AccessCodeComp tosCheck={tosCheck} setTosCheck={setTosCheck} />
            ) : ( */}
            <SignatureComp tosCheck={tosCheck} />
            {/* // )} */}
          </div>
        </div>
      </div>
      {/* <div className="w-full border-2 border-red-500 h-16 flex items-center"></div> */}
      <MobileFooter />
    </main>
  );
}
