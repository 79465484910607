import axios from "axios";
import { useContext, useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
// import { BASE_URL } from "../../Axios";
import NavBar from "../../components/molecules/NavBar";
import MobileFooter from "../../components/organisms/MobileFooter";
import StepsBar from "../../components/organisms/StepsBar";
import TriageContext from "../../context/TriageContext";
import { BASE_URL } from "../../config/api/Axios";

function ProposalNextSteps(props) {
  const [proposalData, setProposalData] = useState({
    ownerName: "",
    createTime: "",
    leadName: "",
    proposal: {},
  });
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState("");
  const [dealId, setDealId] = useState("");
  const { signState, proposalInformation } = useContext(TriageContext);
  const history = useHistory();
  const { id } = useParams();
  const fetchDataFromCRM = async () => {
    setProposalData({
      ownerName: "Drummond Advisors",
      createTime: proposalInformation.Created_Time,
      leadName:
        proposalInformation.Lead?.name ||
        proposalInformation.Contact?.name ||
        "",
      proposal: proposalInformation,
    });
  };

  useEffect(() => {
    if (!proposalInformation.Proposal_Services && !proposalInformation.Proposal_Packages) {
      history.push(`/proposal/${id}`);
    }
  });

  // console.log(proposalData);

  const getUserDeal = async () => {
    await axios({
      method: "get",
      url: `${BASE_URL}/zoho/proposal/${id}`,
    }).then((proposal) => {
      setDealId(proposal.data?.Deal.id);
    });
  };
  useEffect(() => {
    fetchDataFromCRM();
    // getUserDeal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStripeUrl = async () => {
    // const stripeUrl = await axios({
    //   method: "post",
    //   baseURL: `${BASE_URL}/checkout/start`,
    //   data: {
    //     dealId: dealId
    //   }
    // }).then((response) => response)
    // setUrl(stripeUrl.data.url)
  };

  useEffect(() => {
    getStripeUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealId]);

  //   const validateAllSignatures = () => {
  //     return signState.signedStatus;
  //   };

  return (
    <main className="flex flex-col h-screen bg-white">
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 justify-center items-center">
        <div className="flex flex-col items-center space-y-12 w-full md:w-1/2 sm:h-full lg:h-5/6 md:py-8">
          <h1 className="self-center font-dinbold text-4xl md:text-[42px] text-drummond-neutral-400">
            Proposal Accepted
          </h1>
          <div className="w-11/12 md:w-[609px] md:h-[120px] self-center flex flex-col gap-4">
            <p className="text-base font-asap text-drummond-neutral-400 leading-normal">
              Thank you again{" "}
              <span className="font-dinbold text-black text-md">
                {proposalData.leadName}
              </span>{" "}
              for choosing Drummond Advisors. We seek to act in the best
              interests of our clients at all times and provide the highest
              level of professional services.
            </p>
            <p className="font-dinbold text-[18px]">Next Steps</p>
            <p className="text-base font-asap text-drummond-neutral-400 leading-normal">
              This concludes your triage and proposal signing process.{" "}
              <span className="text-drummond-secondary-600">
                Please check your e-mail
              </span>{" "}
              for the signed proposal details. As soon as we can, our team will
              contact you via e-mail to gather the information we need to start
              working on your service order. If you have any questions, please
              feel free to reach out to us via live chat or e-mail.
            </p>
            <p>Kind regards,</p>
            <p className="font-dinbold text-[18px]">Drummond Advisors</p>
          </div>
        </div>
      </div>
      <MobileFooter />
    </main>
  );
}

export default ProposalNextSteps;
