import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL } from "../../config/api/Axios";
import CheckoutCard from "../../components/molecules/CheckoutCard";
import NavBar from "../../components/molecules/NavBar";
import TriageContext from "../../context/TriageContext";

export default function Checkout() {
  const [userName, setUserName] = useState({
    name: "",
    urlToPayment: "",
  });
  const { id } = useParams();
  const { proposalServices, proposalInformation } = useContext(TriageContext);

  const fetchProposalInformation = async () => {
    // eslint-disable-next-line no-unused-vars
    const proposal = await axios({
      method: "get",
      url: `${BASE_URL}/zoho/proposal/${id}`,
    }).then((proposal) => {
      return proposal;
    });
    // const dealId = proposal?.data.Deal.id
    const dealId = "4978288000042240017";
    // console.log(dealId);
    const stripeUrl = await axios({
      method: "post",
      baseURL: `${BASE_URL}/checkout/start`,
      data: {
        dealId: dealId,
      },
    }).then((response) => response);
    // console.log(stripeUrl);
    setUserName({
      name:
        proposalInformation.Lead?.name ||
        proposalInformation.Contact?.name ||
        "",
      url: stripeUrl.data.url,
    });
  };

  useEffect(() => {
    fetchProposalInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col h-screen items-center">
      <NavBar />
      <div className="flex flex-1 flex-col items-center w-full bg-slate-100">
        <div className="w-full h-fit pt-8 flex justify-center items-center">
          <p className="font-robotocond text-center px-6 md:px-20">{`Hello ${userName.name}, you can click the 'Payment' button and we will proceed to the next step.`}</p>
        </div>
        <div className="flex flex-col w-11/12 md:w-4/5 md:flex-row flex-wrap gap-y-12 md:gap-y-6 md:gap-x-14 flex-1 justify-center items-center p-8">
          {proposalServices.map((service, index) => (
            <CheckoutCard key={index} service={service} />
          ))}
        </div>
        <div className="h-12"></div>
        <footer className="fixed h-12 flex flex-row-reverse justify-center items-center left-0 bottom-0 w-full bg-red-900">
          <div className="flex flex-row-reverse justify-between w-full">
            <div className="w-1/3"></div>

            <button className="text-white h-3/4 rounded-md duration-100 px-7 hover:bg-red-700 border-2 border-white">
              <a href={userName.url} target="_blank" rel="noreferrer">
                Payment
              </a>
            </button>
            <p className="text-white flex w-1/3 pl-2">{`Total: $${proposalServices.reduce(
              (acc, curr) => acc + curr.Unit_Price,
              0
            )}`}</p>
          </div>
        </footer>
      </div>
    </div>
  );
}
