import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BASE_URL } from "../../config/api/Axios";
import TriageContext from "../../context/TriageContext";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function SignatureComp(props) {
  const [signatureState, setSignatureState] = useState({
    name: "",
    signedStatus: false,
  });
  const history = useHistory();
  const { id } = useParams();
  const { tosCheck } = props;

  const [locationState, setLocationState] = useState({
    ip: "",
    location: "",
  });

  const {
    signState,
    setSignState,
    proposalInformation,
    servicesID,
    userEmail,
    paymentIntentId
  } = useContext(TriageContext);

  // const handleChange = ({ target }) => {
  //   let value = target.type === "checkbox" ? target.checked : target.value;
  //   setSignatureState({ ...signState, [target.name]: value });
  // };

  const updateStageCrm = async (id, proposalServices) => {
    let PayloadData;
    if (servicesID[0]) {
      PayloadData = {
        id,
        Stage: "Proposal Accepted",
        Proposal_Services: servicesID.map((i) => ({ id: i, _delete: null })),
        // Stripe_Payment_id: paymentIntentId,
        Services: proposalServices,
      };
    } else {
      PayloadData = {
        id,
        Stage: "Proposal Accepted",
        // Stripe_Payment_id: paymentIntentId
      };
    }
    await axios({
      method: "put",
      baseURL: `${BASE_URL}/zoho/updateProposal`,
      data: {
        ...PayloadData,
      },
    });
  };

  const updateFirestoreSignature = async (newSignature) => {
    const triageId = proposalInformation?.Triage_ID;
    await axios({
      method: "patch",
      baseURL: `${BASE_URL}/firebase/${triageId}`,
      // baseURL: `https://us-central1-drummondtechprojects.cloudfunctions.net/api2/firebase/${triageId}`,
      // baseURL: `http://localhost:3001/firebase/${triageId}`,
      data: {
        id: triageId,
        proposal: proposalInformation,
        signature: newSignature,
        SignatureAccessData: locationState,
      },
    }).then((response) => response);
  };
  useEffect(() => {
    getUserLocation();
  }, []);

  const sendProposalEmail = async (newSignature, proposalServices) => {
    let payload;
    if (proposalInformation.Lead) {
      payload = {
        Full_Name: proposalInformation?.Lead.name,
        Email: proposalInformation.Email || userEmail || newSignature.email,
        User_Type: "Leads",
        Proposal_Id: proposalInformation?.id,
        signature: newSignature.name,
        time: newSignature.time.split(" GMT")[0],
        Proposal_Services: proposalServices,
      };
    } else {
      payload = {
        Full_Name: proposalInformation?.Contact.name,
        Email: proposalInformation.Email || userEmail || newSignature.email,
        User_Type: "Contacts",
        Proposal_Id: proposalInformation?.id,
        signature: newSignature.name,
        time: newSignature.time.split(" GMT")[0],
        Proposal_Services: proposalServices,
      };
    }
    try {
      await axios({
        method: "post",
        baseURL: `${BASE_URL}/zoho/sendProposal`,
        // baseURL: `https://us-central1-drummondtechprojects.scloudfunctions.net/api2/zoho/sendProposal`,
        // baseURL: "http://localhost:3001/zoho/sendProposal",
        data: {
          ...payload,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const sendEL = async (newSignature, proposalServices, proposalID) => {
    let payload = {
      Signature: {
        name: newSignature.name,
        date: newSignature.time.split(" GMT")[0],
      },
      Services: proposalServices,
      Email: proposalInformation.Email || userEmail || newSignature.email,
      ProposalID: proposalID,
    };
    if (proposalInformation.Lead) {
      payload = {
        ...payload,
        Name: proposalInformation.Lead.name,
      };
    } else {
      payload = {
        ...payload,
        Name: proposalInformation.Contact.name,
      };
    }
    await axios({
      method: "post",
      baseURL: `${BASE_URL}/zoho/sendEL`,
      data: {
        ...payload,
      },
    });
  };
  const redirectToPage = (route) => {
    if (route === "checkout") {
      history.push(`/${route}/${id}`);
    } else {
      history.push(`/proposal/${id}/${route}`);
    }
  };

  const submitSignature = async ({ target }) => {
    // if (signatureState.name.length > 3) {
    const newSignature = {
      // The Accept Proposal button instead of the text input with the name makes it necessary to have this "||"
      name: signatureState.name || "",
      signedStatus: true,
      email: proposalInformation.Email,
      time: Date(),
    };
    setSignState(newSignature);
    updateFirestoreSignature(newSignature);
    setSignatureState({
      name: "",
      signedStatus: false,
    });
    const proposalServices = [];
    if(proposalInformation.Proposal_Services) {
      proposalInformation?.Proposal_Services.forEach((i) => {
        if (!servicesID.includes(i.id)) {
          proposalServices.push(i);
        }
      });
    } else {
      proposalInformation.Proposal_Packages.forEach((i) => {
        if (!servicesID.includes(i.id)) {
          proposalServices.push(i);
        }
      });
    }
    redirectToPage("payment");

    updateStageCrm(proposalInformation?.id, proposalServices);
    sendProposalEmail(newSignature, proposalServices);
    setTimeout(async () => {
      await sendEL(newSignature, proposalServices, proposalInformation?.id);
    }, 25000);
    // }
  };

  const getUserLocation = async () => {
    const requestOptions = {
      method: "GET",
    };
    // city.name, state.name, contry.name,
    // ip,
    await fetch(
      "https://api.geoapify.com/v1/ipinfo?&apiKey=16394f665261410bbfcd772ba9cc93dd",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
        setLocationState({
          location: `${result.city.name} - ${result.state.name} - ${result.country.name}`,
          ip: result.ip,
          plataform: navigator.platform,
        })
      )
      .catch((error) => console.log("error", error));
  };

  if (signState.signedStatus) {
    return (
      <div className="flex flex-col gap-[8px]">
        <div className="w-full flex gap-8 items-center justify-center mt-4 md:mt-0">
          {/* <div className="flex items-center w-full text-[16px] border bg-drummond-secondary-500 border-green-600 enabled:active:border-drummond-primary h-[50px] rounded-[10px] enabled:outline-drummond-primary px-[14px] py-[16px] disabled:cursor-not-allowed">
            <h3 className="font-shadow text-green-800 text-[18px]">
              {signState.name}
            </h3>
          </div> */}
          <div className="flex justify-center items-center h-[48px] md:h-[56px] w-[240px] md:w-[400px] font-dinbold text-white rounded-md duration-100 bg-drummond-secondary-500 border-green-600 border">
            <h3 className="font-dinbold text-drummond-neutral-400 text-[18px]">
              Proposal Accepted
            </h3>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-[8px] h-20 items-center justify-center">
        {/* <div className="w-full flex gap-8 items-center"> */}
        {/* <input
            type={"text"}
            className="font-shadow w-full text-[22px] border border-drummond-neutral-200 enabled:active:border-drummond-primary h-[50px] rounded-[10px] enabled:outline-drummond-primary px-[14px] py-[16px] ring-[2px] ring-drummond-primary"
            onChange={(e) => handleChange(e)}
            name="name"
            value={signatureState.name}
            id="signature"
            placeholder="Signature"
          ></input> */}
        <button
          // className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary enabled:hover:bg-drummond-secondary-300 enabled:active:bg-drummond-secondary-400 disabled:bg-opacity-[0.3] disabled:cursor-not-allowed"
          className="h-[48px] md:h-[56px] w-[240px] md:w-[400px] font-dinbold items-center text-lg text-white rounded-md duration-100 bg-drummond-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400 disabled:hover:bg-drummond-primary disabled:cursor-not-allowed disabled:bg-opacity-30 disabled:hover:bg-opacity-30"
          onClick={(e) => submitSignature(e)}
          disabled={!tosCheck}
        >
          Accept Proposal
        </button>
      </div>
      // </div>
    );
  }
}
