/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Textarea } from "@material-tailwind/react";
import axios from "axios";
import { Fragment, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { BASE_URL } from "../../config/api/Axios";

export default function RejectPropModal(props) {
  //   const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const { openCloseFunction, openState } = props;

  const [reasonState, setReasonState] = useState("");

  const [reasonRadio, setReasonRadio] = useState("");

  const router = useHistory();

  const proposalId = router.location.pathname.split("/")[2];

  const handleRejectSubmit = async () => {
    console.log("Rejecting proposal");
    await axios({
      method: "put",
      baseURL: `${BASE_URL}/zoho/updateproposalandnotes`,
      data: {
        id: proposalId,
        Stage: "Proposal Declined",
        notes: `${reasonRadio}, ${reasonState}`,
      },
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Proposal Rejected.",
      text: "Your have rejected the proposal. A Drummond representative will contact you as soon as possible!",
      showConfirmButton: false,
      timer: 2500,
    });
    window.location.replace("https://drummondadvisors.com/");
  };

  const handleTextareaChange = ({ target }) => {
    setReasonState(target.value);
  };

  const handleRadioChange = ({ target }) => {
    setReasonRadio(target.value);
  };

  return (
    <Transition.Root show={openState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={openCloseFunction}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white pb-[32px] rounded-[5px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[709px] sm:w-full">
              <div className="sm:flex sm:items-start h-full">
                <div className="mt-3 text-center sm:mt-0 w-full">
                  <Dialog.Title className="text-[24px] h-[75px] px-[32px] flex justify-between items-center text-start leading-6 font-medium text-gray text-drummond-neutral-400-900">
                    <h1 className="text-drummond-neutral-400 font-dinbold text-[24 text-drummond-neutral-400px]">
                      Decline Proposal
                    </h1>
                    <button
                      type="button"
                      onClick={() => openCloseFunction(false)}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="text-black h-7 w-7" />
                    </button>
                  </Dialog.Title>
                  {/* <hr className="my-4 border-gray-600 "></hr> */}
                  <div className="mt-2 flex flex-col items-center">
                    <p className="font-dinbold text-[16 text-drummond-neutral-400px] text-drummond-neutral-400">
                      Are you sure you want to decline the proposal? Can you
                      please tell us the reason for the decline?
                    </p>
                  </div>
                  <div className="w-full flex flex-col">
                    <div className="px-[32px]">
                      <label className="label cursor-pointer border-b border-drummond-neutral-100 h-[56px] flex justify-start gap-6">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-drummond-primary"
                          value="I've changed my mind"
                        />
                        <span className="font-asap text-[16px] text-drummond-neutral-400">
                          I've changed my mind
                        </span>
                      </label>
                      <label className="label cursor-pointer border-b border-drummond-neutral-100 h-[56px] flex justify-start gap-6">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-drummond-primary"
                          value="I'm not sure about this proposal"
                        />
                        <span className="font-asap text-[16px] text-drummond-neutral-400">
                          I'm not sure about this proposal
                        </span>
                      </label>
                      <label className="label cursor-pointer border-b border-drummond-neutral-100 h-[56px] flex justify-start gap-6">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-drummond-primary"
                          value="It's too expensive for me"
                        />
                        <span className="font-asap text-[16px] text-drummond-neutral-400">
                          It's too expensive for me
                        </span>
                      </label>
                      <label className="label cursor-pointer h-[56px] flex justify-start gap-6">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          name="radio-10"
                          className="radio checked:bg-drummond-primary"
                          value="Other reasons"
                        />
                        <span className="font-asap text-[16px] text-drummond-neutral-400">
                          Other
                        </span>
                      </label>
                    </div>
                    <div className="w-full px-[32px] mt-2 h-[105px] flex">
                      <Textarea
                        label="Additional Notes"
                        onChange={handleTextareaChange}
                        className="h-[100px]"
                      />
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 flex justify-between md:justify-end gap-3 px-[32px]">
                    <button
                      type="button"
                      className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400 focus:ring-drummond-primary"
                      onClick={() => openCloseFunction(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400 disabled:opacity-[0.3] disabled:cursor-not-allowed disabled:hover:bg-primary"
                      onClick={() => handleRejectSubmit()}
                      disabled={!reasonRadio}
                    >
                      Decline
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
